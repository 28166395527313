import React from 'react'
import { Col } from '@semcore/ui/grid'
import ProfilePhotoUrl from 'assets/profile-photo.png?url'
import {
  Info,
  CVPhotoContainer,
  Description,
  Introduction,
  Container,
  PhotoContainer,
  DndHobby,
  CycleHobby,
  DogHobby,
  DanceHobby,
  PhotoHobby,
  Greeting,
} from './styled'

const About: React.FC = () => {
  return (
    <Container gutter={7.5} justifyContent="center">
      <Col span={10} sm={12} style={{ display: 'flex' }}>
        <Info>
          <Introduction>
            I am Denis, <b>Sound Designer</b> from Prague (CZ)
          </Introduction>
          <Description>
            Professionally doing <i>beeps</i> and <i>booms</i> that you will remember
          </Description>
        </Info>
        <CVPhotoContainer>
          <PhotoContainer>
            <img src={ProfilePhotoUrl} alt="I'm with my dog" style={{ width: '-webkit-fill-available' }} />
            <DndHobby />
            <CycleHobby />
            <DogHobby />
            <PhotoHobby />
            <DanceHobby />
            <Greeting>hey!</Greeting>
          </PhotoContainer>
        </CVPhotoContainer>
      </Col>
    </Container>
  )
}

export default React.memo(About)
