import React from 'react'
import { useWasBlockVisible } from 'helpers/hooks/use-was-block-visible'
import { Container } from './styled'
import { AccentInTitle, StoryBlock } from '../story-block'

const GameAudioBlock: React.FC = () => {
  const { ref, wasVisibleBlock } = useWasBlockVisible()

  return (
    <Container ref={ref} $isVisible={wasVisibleBlock}>
      <StoryBlock description="I have done audio for czech studios (CBE, GRIP, Batfields, etc.) and worldwide (Audioguys, Sega).">
        <AccentInTitle>Successfully shifted career to</AccentInTitle>&nbsp;game audio
      </StoryBlock>
    </Container>
  )
}

export default React.memo(GameAudioBlock)
