import React from 'react'
import { Row } from '@semcore/ui/grid'
import { Box } from '@semcore/ui/flex-box'
import { CompanyType } from '../../constants'
import { Container, ContentWrapper, Job, JobTitle, Name, Year } from './styled'
import { useIsMobile } from '../../../breakpoints_provider'

interface Props {
  company: CompanyType
}

const Company: React.FC<Props> = ({ company: { link, name, year, position, description } }) => {
  const isMobile = useIsMobile()

  const companyName = (
    <Name href={link} target="_blank">
      {name}
    </Name>
  )

  return (
    <Container $strongBorder={Boolean(year)}>
      <Row gutter={7.5} justifyContent="center">
        <ContentWrapper span={10} sm={12}>
          <Year>{year}</Year>
          <Job>
            <JobTitle>{position}</JobTitle>
            {isMobile && companyName}
            <Box mt="4%">{description}</Box>
          </Job>
          {!isMobile && (
            <Name href={link} target="_blank">
              {name}
            </Name>
          )}
        </ContentWrapper>
      </Row>
    </Container>
  )
}

export default React.memo(Company)
