import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'

export const Title = styled.h3`
  font-family: var(--accent-font);
  font-size: var(--fs-500);
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  line-height: 120%;
`

export const AccentInTitle = styled.span`
  color: var(--font-color-secondary);
`

export const Description = styled.p`
  font-size: var(--fs-300);
  color: var(--font-color-secondary);
  margin: 3% 0 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 6% 0 0;
    font-size: var(--fs-100);
  }
`
