import React from 'react'
import { Description, Title } from './styled'

interface Props {
  children: React.ReactNode
  description: React.ReactNode
}

const StoryBlock: React.FC<Props> = ({ children, description }) => {
  return (
    <>
      <Title>{children}</Title>
      <Description>{description}</Description>
    </>
  )
}

export default React.memo(StoryBlock)
