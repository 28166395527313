export const FACEBOOK_LINK = 'https://www.facebook.com/denmir048'
export const TWITTER_LINK = 'https://twitter.com/denmir048'
export const LINKEDIN_LINK = 'https://www.linkedin.com/in/denis-liudkevich/'
export const TELEGRAM_LINK = 'https://t.me/denmir048'
export const EMAIL = 'denmir048@gmail.com'

export const YOU_TUBE_LINK = 'https://youtube.com/@denisliudkevich7787?si=9eMvYXqwHo1P9-sY'

export const PORTFOLIO_LINK = 'https://drive.google.com/file/d/1lBgPj6IBzHnaAkPa0qio-_8PLRF6Oqlp/view?usp=drivesdk'

export const REEL_LINK = 'https://player.vimeo.com/video/794295159?autoplay=1&loop=1&autopause=0'

export const CONTACTS_BLOCK = 'contacts'

export const PROJECTS_BLOCK = 'projects'
