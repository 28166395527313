import styled from 'styled-components'
import NoiseUrl from '../../assets/noise.png?url'
import { ContentWrapperStyles } from '../content-wrapper'

export const MainScreen = styled.div`
  background-color: var(--bg-color);
  background-image: url(${NoiseUrl});
  background-repeat: repeat;
  background-size: contain;
  color: var(--font-color-secondary);
  ${ContentWrapperStyles};
`
