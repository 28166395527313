import React from 'react'
import { Text, Year } from './styled'

const Signature: React.FC = () => {
  return (
    <Text>
      Denis Liudkevich <Year>* 2023</Year>
    </Text>
  )
}

export default React.memo(Signature)
