import styled from 'styled-components'

export const Container = styled.p`
  position: absolute;
  left: 0.65vw;
  top: calc(50% - 5vw);
  display: flex;
  flex-direction: column;
  gap: 2vw;
`

export const IconLink = styled.a`
  * {
    transition: fill 300ms linear;
  }

  svg {
    height: 1.3vw;
    width: 1.3vw;
  }

  &:hover {
    cursor: pointer;
    * {
      fill: var(--blue);
    }
  }
`
