import React from 'react'
import FbIcon from 'assets/facebook-icon.svg'
import LinkedInIcon from 'assets/linkedin-icon.svg'
import TgIcon from 'assets/telegram-icon.svg'
import TwitterIcon from 'assets/twitter-icon.svg'
import { FACEBOOK_LINK, LINKEDIN_LINK, TELEGRAM_LINK, TWITTER_LINK } from 'constants/links'
import { Container, IconLink } from './styled'

const Icons: React.FC = () => {
  return (
    <Container>
          <IconLink href={LINKEDIN_LINK} target="_blank">
              <LinkedInIcon />
          </IconLink> 
      <IconLink href={TWITTER_LINK} target="_blank">
        <TwitterIcon />
      </IconLink>
     
          <IconLink href={FACEBOOK_LINK} target="_blank">
              <FbIcon />
          </IconLink>
      <IconLink href={TELEGRAM_LINK} target="_blank">
        <TgIcon />
      </IconLink>
    </Container>
  )
}

export default React.memo(Icons)
