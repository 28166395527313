import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row } from '@semcore/ui/grid'
import PlayIcon from 'assets/play-icon.svg'
import { REEL_LINK } from 'constants/links'
import { PlayButton, ReelContainer, VideoStub } from './styled'
import { Icons } from './components/icons'
import { Signature } from './components/signature'
import { useIsMobile } from '../breakpoints_provider'

const Reel: React.FC = () => {
  const [isVisibleVideo, seIsVisibleVideo] = useState<boolean>(false)
  const [isVisibleVideoStub, setIsVisibleVideoStub] = useState<boolean>(true)
  const [containerHeight, setContainerHeight] = useState(0)
  const iframeContainerRef = useRef<null | HTMLIFrameElement>(null)
  const iframeRef = useRef(null)
  const isMobile = useIsMobile()

  const updateIframeSize = () => {
    const width = iframeContainerRef.current?.clientWidth
    if (width) {
      setContainerHeight((width / 16) * 9)
    }
  }

  useEffect(() => {
    updateIframeSize()
    window.addEventListener('resize', updateIframeSize)

    return () => {
      window.removeEventListener('resize', updateIframeSize)
    }
  }, [])

  const handleClickPlay = useCallback(() => {
    seIsVisibleVideo(true)
    setTimeout(() => {
      setIsVisibleVideoStub(false)
    }, 1000)
  }, [])

  return (
    <Row gutter={7.5} justifyContent="center" position="relative">
      {!isMobile && <Icons />}
      <Col span={10} sm={12}>
        <ReelContainer $height={containerHeight} ref={iframeContainerRef}>
          {isVisibleVideo && (
            <iframe
              ref={iframeRef}
              src={REEL_LINK}
              width="100%"
              height={containerHeight}
              onLoad={updateIframeSize}
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Denis Liudkevich reel"
            />
          )}
          {isVisibleVideoStub && (
            <VideoStub $isVisible={!isVisibleVideo}>
              <PlayButton onClick={handleClickPlay} type="button">
                <PlayIcon />
                Demo reel
              </PlayButton>
            </VideoStub>
          )}
        </ReelContainer>
      </Col>
      {!isMobile && <Signature />}
    </Row>
  )
}

export default React.memo(Reel)
