import styled from 'styled-components'
import { Col } from '@semcore/ui/grid'
import { MOBILE_BREAKPOINT } from 'helpers/constants'

export const Container = styled(Col)`
  color: var(--font-color-secondary);
  margin-top: 9%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 13%;
  }
`

export const BgWrapper = styled.div`
  background-color: var(--bg-color);
`

export const ContentWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 8% 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 11% 0;
  }
`

export const ProjectsGrid = styled.ul`
  display: grid;
  grid-column-gap: 17%;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  margin: 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-column-gap: 35px;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Title = styled.h2`
  font-size: var(--fs-100);
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
`
