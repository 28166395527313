import React from 'react'
import { Row } from '@semcore/ui/grid'
import YearsImage from 'assets/years-experience.svg'

import { Container, ContentWrapper, Description, PrimaryTitle, SecondaryTitle } from './styled'

const JobSummary: React.FC = () => {
  return (
    <Container>
      <Row gutter={7.5} justifyContent="center">
        <ContentWrapper span={8} sm={11}>
          <SecondaryTitle>
            7 years in the game (audio)
            <br />
            <PrimaryTitle>
              <YearsImage /> in the game audio
            </PrimaryTitle>
          </SecondaryTitle>
          <Description>I found myself comfortable both in a bigger studio and with an indie devs</Description>
        </ContentWrapper>
      </Row>
    </Container>
  )
}

export default React.memo(JobSummary)
