import React, { useContext } from 'react'
import { createBreakpoints, BreakpointsProps, MediaList } from '@semcore/ui/breakpoints'
import { MOBILE_BREAKPOINT } from 'helpers/constants'

export const Breakpoints = {
  mobile: MOBILE_BREAKPOINT,
} as const

const MEDIA = Object.values(Breakpoints).map((maxWidth) => `(max-width: ${maxWidth}px)`)

type Provider = (media: string[]) => ((props: BreakpointsProps) => React.ReactElement) & {
  Context: React.Context<0 | 1 | 2>
  mediaList: MediaList
}

export const BreakpointsProvider = (createBreakpoints as Provider)(MEDIA)

BreakpointsProvider.Context.displayName = 'Breakpoints'

export const useIsMobile = () => {
  const index = useContext(BreakpointsProvider.Context)
  return index === 0
}
