import LogoSvg from 'assets/logo.svg'
import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'

export const Logo = styled(LogoSvg)`
  width: 2.2vw;
  height: 2vw;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 7.2vw;
    height: 6.5vw;
  }
`

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  padding-top: 2.5%;
  padding-bottom: 2%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 5%;
    padding-bottom: 4.5%;
  }
`

export const Link = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  color: var(--gray);
  transition: all 300ms linear;

  &:hover {
    color: var(--fair);
    font-weight: bold;
  }
`

export const LinksContainer = styled.div`
  gap: 2vw;
  display: flex;
  align-items: center;
`
