import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'
import StoryPathUrl from 'assets/story-path-2.svg?url'
import StoryPathMobileUrl from 'assets/story-path-2-mobile.svg?url'

export const Container = styled.div<{ $isVisible: boolean }>`
  width: 80%;
  margin-left: auto;
  position: relative;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
  transition: 2s opacity ease;

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: -24%;
    background-image: url(${StoryPathUrl});
    background-repeat: no-repeat;
    width: 40%;
    background-size: contain;
    height: 90%;
    bottom: -15%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 77%;

    &:after {
      background-image: url(${StoryPathMobileUrl});
      left: -32%;
      width: 40%;
      bottom: -12%;
    }
  }
`

export const IconLink = styled.a`
  & > * {
    fill: var(--fair);
    vertical-align: bottom;
    height: 3.4vw;
    width: 3.4vw;
  }

  &:hover > * {
    transition: fill 300ms ease-in;
    fill: var(--dark);
  }
`
