import styled, { keyframes } from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'

const slide = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`

export const Container = styled.div`
  font-size: var(--fs-400);
  text-transform: uppercase;
  background-color: var(--blue);
  color: var(--font-color-secondary);
  font-family: var(--accent-font);
  overflow: hidden;
  padding: 1%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: var(--fs-200);
  }
`

export const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: ${slide} 20s linear infinite;

  & > p {
    display: inline-block;
  }
`

export const TransparentWord = styled.span`
  color: transparent;
  -webkit-text-stroke-color: var(--font-color-secondary);
  -webkit-text-stroke-width: 1px;
`
