import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'
import { Col } from '@semcore/ui/grid'
import { ContentWrapperStyles } from 'components/content-wrapper'

export const Container = styled.div<{ $strongBorder: boolean }>`
  padding: 4% 0;
  border-top: 1px solid ${({ $strongBorder }) => ($strongBorder ? 'var(--fair)' : 'rgba(249, 247, 250, 0.30)')};
  transition: background-color 800ms ease;

  &:hover {
    background-color: var(--blue);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 7% 0;
  }

  ${ContentWrapperStyles};
`

export const Year = styled.p`
  width: 20%;
  margin: 0;
  font-size: var(--fs-300);
`

export const Job = styled.div`
  width: 40%;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 66%;
  }
`

export const JobTitle = styled.h3`
  text-transform: uppercase;
  font-family: var(--accent-font);
  font-size: var(--fs-300);
  font-weight: 400;
  margin: 0;
  line-height: 1;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: var(--fs-500);
  }
`

export const Name = styled.a`
  width: 12%;
  font-style: italic;
  display: block;
  color: var(--font-color-secondary);
  transition: color 300ms linear;

  &:hover {
    color: var(--dark);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-top: 3%;
  }
`

export const ContentWrapper = styled(Col)`
  display: flex;
  justify-content: space-between;
`
