import styled from 'styled-components'

export const Text = styled.p`
  writing-mode: vertical-lr;
  position: absolute;
  right: var(--fs-100);
  top: calc(50% - 5vw);
`

export const Year = styled.span`
  color: var(--gray);
  font-style: italic;
`
