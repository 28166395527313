import { css } from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'

export const ContentWrapperStyles = css`
  padding-left: 4%;
  padding-right: 4%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 3%;
    padding-right: 3%;
  }
`
