import React from 'react'
import { PROJECTS_BLOCK } from 'constants/links'
import { BgWrapper, Container, ContentWrapper, ProjectsGrid, Title } from './styled'
import { Project } from './components/project'
import { projects } from './constants'

const Projects: React.FC = () => {
  return (
    <Container span={10} sm={12} id={PROJECTS_BLOCK}>
      <BgWrapper>
        <ContentWrapper>
          <Title>Selected Projects</Title>
          <ProjectsGrid>
            {projects.map((project) => (
              <Project
                key={project.title}
                title={project.title}
                description={project.role}
                playLink={project.gameLink}
                watchLink={project.reelLink}
              />
            ))}
          </ProjectsGrid>
        </ContentWrapper>
      </BgWrapper>
    </Container>
  )
}

export default React.memo(Projects)
