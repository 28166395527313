import React from 'react'

import { useWasBlockVisible } from 'helpers/hooks/use-was-block-visible'
import { Container } from './styled'
import { StoryBlock, AccentInTitle } from '../story-block'

const UniversityBlock: React.FC = () => {
  const { ref, wasVisibleBlock } = useWasBlockVisible()

  return (
    <Container ref={ref} $isVisible={wasVisibleBlock}>
      <StoryBlock description="Engineer's degree in Audio Engineering BUT FEEC and CTU Prague FEE">
        <AccentInTitle>Creative artist with a</AccentInTitle>&nbsp; technical background
      </StoryBlock>
    </Container>
  )
}

export default React.memo(UniversityBlock)
