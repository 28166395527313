import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'
import { Flex } from '@semcore/ui/flex-box'
import { ContentWrapperStyles } from '../content-wrapper'

export const Container = styled.div`
  background-color: var(--bg-color);
  color: var(--font-color-secondary);
`

export const MoreLink = styled.a`
  text-transform: uppercase;
  font-family: var(--accent-font);
  font-size: var(--fs-300);
  font-weight: 400;
  color: var(--font-color-secondary);
  text-decoration: none;
  transition: color 300ms linear;
  display: flex;
  gap: 8px;
  line-height: 1.1;

  & * {
    transition: all 300ms linear;
  }

  & > svg {
    position: relative;
    top: 0;
    left: 0;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      height: 2vw;
      width: 2vw;
    }
  }

  &:hover {
    color: var(--blue);

    & * {
      fill: var(--blue);
    }

    & > svg {
      top: -3px;
      left: 3px;
    }
  }
`

export const MoreContainer = styled(Flex)`
  padding: 4% 0;
  border-top: 1px solid var(--fair);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 7% 0;
  }

  ${ContentWrapperStyles};
`
