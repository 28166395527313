import React from 'react'
import { CONTACTS_BLOCK, PROJECTS_BLOCK } from 'constants/links'
import { Container, Link, Logo, LinksContainer } from './styled'

const Header: React.FC = () => {
  return (
    <Container>
      <Logo />
      <LinksContainer>
        <Link href={`#${PROJECTS_BLOCK}`}>Projects</Link>
        <Link href={`#${CONTACTS_BLOCK}`}>Contacts</Link>
      </LinksContainer>
    </Container>
  )
}

export default React.memo(Header)
