import React from 'react'
import { PORTFOLIO_LINK } from 'constants/links'
import Arrow from 'assets/link-arrow-more.svg'
import { Container, MoreContainer, MoreLink } from './styled'
import { companies } from './constants'
import { Company } from './components/company'

const Experience: React.FC = () => {
  return (
    <Container>
      {companies.map((company) => (
        <Company company={company} key={company.name} />
      ))}
      <MoreContainer justifyContent="center">
        <MoreLink href={PORTFOLIO_LINK} target="_blank">
          Full CV
          <Arrow />
        </MoreLink>
      </MoreContainer>
    </Container>
  )
}

export default React.memo(Experience)
