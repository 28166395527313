import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'

export const Container = styled.div<{ $isVisible: boolean }>`
  width: 70%;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
  transition: 2s opacity ease;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
  }
`
