import React from 'react'
import { Footer } from '../footer'
import { MyStory } from '../my-story'
import { Experience } from '../experience'
import { BreakpointsProvider } from '../breakpoints_provider'
import { JobSummary } from '../job-summary'
import { Ticker } from '../ticker'
import { MainScreen } from './styled'
import { Header } from '../header'
import { Reel } from '../reel'
import { About } from '../about'

import 'styles/global.css'

const App: React.FC = () => {
  return (
    <BreakpointsProvider>
      <main>
        <MainScreen>
          <Header />
          <Reel />
          <About />
        </MainScreen>
        <Ticker />
        <JobSummary />
        <Experience />
        <MyStory />
      </main>
      <Footer />
    </BreakpointsProvider>
  )
}

export default React.memo(App)
