import React from 'react'
import { Row } from '@semcore/ui/grid'
import { Container, Stories } from './styled'
import { UniversityBlock } from './components/universty-block'
import { FilmsBlock } from './components/fimls-block'
import { GameAudioBlock } from './components/game-audio-block'
import { Projects } from './components/projects'

const MyStory: React.FC = () => {
  return (
    <Container>
      <Row gutter={7.5} justifyContent="center">
        <Stories span={10} sm={12}>
          <UniversityBlock />
          <FilmsBlock />
          <GameAudioBlock />
        </Stories>
        <Projects />
      </Row>
    </Container>
  )
}

export default React.memo(MyStory)
