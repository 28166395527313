export interface CompanyType {
  name: string
  link: string
  position: string
  description: string
  year?: string
}

export const companies: CompanyType[] = [
  {
    year: '2023-now',
    name: 'TheAudioguys',
    link: 'https://www.theaudioguys.co.uk/',
    position: 'Sound Designer',
        description: 'I did sound design on projects for SEGA HARDlight and other studios. Creatures, weapons, abilities, ambience, character, etc',
  },
  {
    year: '',
    name: 'Batfields',
    link: 'https://www.batfields.com/',
    position: 'Sound Designer',
      description: 'I was responsible for designing audio systems, sound design and audio testing. Unreal Engine 5, Metasounds, Soundscapes',
  },
  {
    year: '2022-2023',
    name: 'Artlist.io',
    link: 'https://artlist.io/',
    position: 'Sound Library Creator',
    description:
      'I delivered a several sound libraries on the following themes: metal, rubber, paper, whooshes, church ambience',
  },
  {
    year: '',
    name: 'Grip Studios',
    link: 'https://www.grip-digital.com/',
    position: 'Sound Designer',
    description:
      'I was responsible for audio direction, sound design and production. Unreal Engine 4.',
  },
  {
    year: '2021-2023',
    name: 'CBE Software',
    link: 'https://cbe-software.com/',
    position: 'Sound Designer',
      description: 'I designed sounds for monsters, environment, combat, UI and character. I also implemented them in FMOD + Unreal Engine 5.',
  },
]
