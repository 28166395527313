import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'
import { Row } from '@semcore/ui/grid'
import ProfileSvg from 'assets/profile-image.svg'

import { ContentWrapperStyles } from '../content-wrapper'

export const Container = styled.footer`
  background-color: var(--footer-bg);

  padding: 8% 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 6% 0;
  }

  ${ContentWrapperStyles};
`

export const EmailText = styled.span``

export const MainLink = styled.a`
  color: var(--font-color-primary);
  text-transform: uppercase;
  font-size: var(--fs-500);
  font-family: var(--accent-font);
  cursor: pointer;
  display: flex;
  text-decoration: none;
  gap: 1vw;
  position: relative;
  top: 0;
  right: 0;
  transition: all 300ms linear;

  &:hover > ${EmailText} {
    color: var(--white);
    background-color: var(--blue);
    transition: all 300ms linear;
  }

  &:hover {
    top: 3px;
    right: -3px;
  }
`

export const ContactLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-size: var(--fs-200);
  line-height: 200%;
  transition: all 300ms ease-in;
  cursor: pointer;
  color: var(--dark);

  &:hover {
    font-weight: bold;
    color: var(--font-color-primary);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: var(--fs-100);
  }
`

export const LinksContainer = styled(Row)`
  margin-top: 6%;

  &&& {
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-top: 5%;
      width: 80%;
    }
  }
`

export const ProfileImage = styled(ProfileSvg)`
  height: auto;
  right: 10%;
  width: 20%;
  position: absolute;
  top: -130%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    bottom: -290%;
    top: unset;
    right: 0;
    width: 27%;
  }
`
