import React from 'react'
import { Container, Text, TransparentWord } from './styled'

const Ticker: React.FC = () => {
  const text = (
    <>
      * sound design * <TransparentWord>recording</TransparentWord> * post-production *{' '}
      <TransparentWord>game implementation </TransparentWord>
    </>
  )
  return (
    <Container>
      <Text>
        <p>{text}</p> <p>{text}</p>
      </Text>
    </Container>
  )
}

export default React.memo(Ticker)
