import styled from 'styled-components'
import ImageSvg from 'assets/video-background.svg?url'
import { MOBILE_BREAKPOINT } from '../../helpers/constants'

export const VideoStub = styled.div<{ $isVisible: boolean }>`
  background-image: url(${ImageSvg});
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: ${({ $isVisible }): number => ($isVisible ? 1 : 0)};
  transition: opacity 1000ms linear;
  top: 0;
`

export const ReelContainer = styled.div<{ $height: number }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${({ $height }) => $height}px;
`

export const PlayButton = styled.button`
  background-color: var(--fair);
  color: var(--font-color-primary);
  text-transform: uppercase;
  font-size: var(--fs-300);
  border: none;
  padding: 2% 3%;
  transition: all 300ms linear;
  cursor: pointer;
  text-wrap: nowrap;
  display: flex;
  align-items: center;

  * {
    transition: all 300ms linear;
  }

  &:hover {
    color: var(--font-color-secondary);
    background-color: var(--blue);

    * {
      fill: var(--fair);
    }
  }

  & > svg {
    margin-right: 1vw;
    width: 2vw;
    height: 1.9vw;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: var(--fs-100);
  }
`
