import React from 'react'
import IconYouTube from 'assets/icon-you-tube.svg'
import { YOU_TUBE_LINK } from 'constants/links'
import { useWasBlockVisible } from 'helpers/hooks/use-was-block-visible'

import { Container, IconLink } from './styled'
import { StoryBlock, AccentInTitle } from '../story-block'

const FilmsBlock: React.FC = () => {
  const { ref, wasVisibleBlock } = useWasBlockVisible()

  const description = (
    <>
      Sound for brands like STIHL, YTONG, Horsefeathers, Czech TV, Brno City:{' '}
      <IconLink href={YOU_TUBE_LINK} target="_blank">
        <IconYouTube />
      </IconLink>
    </>
  )
  return (
    <Container ref={ref} $isVisible={wasVisibleBlock}>
      {/* TODO: here will be the link to youtube */}
      <StoryBlock description={description}>
              <AccentInTitle>Started with a</AccentInTitle>&nbsp;linear sound&nbsp;
              <AccentInTitle> for films, TV, and commercials</AccentInTitle>
      </StoryBlock>
    </Container>
  )
}

export default React.memo(FilmsBlock)
