import React from 'react'
import { Flex } from '@semcore/ui/flex-box'
import { Container, Title, Link, Description } from './styled'

interface Props {
  title: string
  description: string
  watchLink?: string
  playLink?: string
}

const Project: React.FC<Props> = ({ title, playLink, watchLink, description }) => {
  return (
    <Container>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>

      <Flex gap="12%">
        {watchLink && (
          <Link href={watchLink} target="_blank">
            Watch
          </Link>
        )}
        {playLink && (
          <Link href={playLink} target="_blank">
            Play
          </Link>
        )}
      </Flex>
    </Container>
  )
}

export default React.memo(Project)
