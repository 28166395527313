import styled, { css } from 'styled-components'
import { Row } from '@semcore/ui/grid'
import Hobby1 from 'assets/hobby-1.svg'
import Hobby2 from 'assets/hobby-2.svg'
import Hobby3 from 'assets/hobby-3.svg'
import Hobby4 from 'assets/hobby-4.svg'
import Hobby5 from 'assets/hobby-5.svg'
import { MOBILE_BREAKPOINT } from '../../helpers/constants'

export const Container = styled(Row)`
  padding: 9.5% 0 5%;

  --hobby-width: 12%;
  --hobby-half-width: calc(var(--hobby-width) / 2);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    --hobby-width: 15%;
    padding: 7.5% 0 8.5%;
  }
`

export const Info = styled.div`
  width: 50%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 61%;
  }
`

export const CVPhotoContainer = styled.div`
  width: 50%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    left: 46%;
    position: absolute;
  }
`

export const PhotoContainer = styled.div`
  width: 80%;
  margin-left: auto;
  position: relative;
`

export const Description = styled.p`
  font-size: var(--fs-300);
  max-width: 800px;
  margin-top: 7%;

  & i {
    font-style: italic;
  }

  @media (min-width: 2000px) {
    max-width: 90%;
  }
`

export const Introduction = styled.h1`
  font-size: var(--fs-500);
  font-family: var(--accent-font);
  text-transform: uppercase;
  line-height: 120%;

  & b {
    color: var(--font-color-primary);
  }
`

const HobbyCss = css`
  position: absolute;
  height: var(--hobby-width);
  width: var(--hobby-width);
`

export const DndHobby = styled(Hobby1)`
  ${HobbyCss};
  left: calc(20% - var(--hobby-half-width));
  top: calc(11% - var(--hobby-half-width));

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    top: calc(50% - var(--hobby-half-width));
    left: calc(-2% - var(--hobby-half-width));
  }
`

export const CycleHobby = styled(Hobby2)`
  ${HobbyCss};
  top: calc(10% - var(--hobby-half-width));
  right: calc(20% - var(--hobby-half-width));
`

export const DanceHobby = styled(Hobby3)`
  ${HobbyCss};
  top: calc(50% - var(--hobby-half-width));
  right: calc(4% - var(--hobby-half-width));
`

export const PhotoHobby = styled(Hobby4)`
  ${HobbyCss};
  bottom: calc(10% - var(--hobby-half-width));
  right: calc(20% - var(--hobby-half-width));
`

export const DogHobby = styled(Hobby5)`
  ${HobbyCss};
  left: calc(20% - var(--hobby-half-width));
  bottom: calc(11% - var(--hobby-half-width));
`

export const Greeting = styled.span`
  position: absolute;
  color: var(--font-color-primary);
  top: calc(50% - 8%);
  left: calc(-2% - 8%);
  background-color: var(--fair);
  transform: rotate(18.675deg);
  border: 3px solid var(--blue);
  line-height: 120%;
  font-family: var(--accent-font);
  font-size: var(--fs-300);
  padding: 0.4vw 0.8vw;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: var(--fs-200);
    border-width: 1px;

    left: calc(11% - 8%);
    top: calc(15% - 8%);
  }
`
