interface Project {
  title: string
  role: string
  reelLink?: string
  gameLink?: string
}

export const projects: Project[] = [
  {
    title: 'Parliament',
        role: 'Sound Design: Ambience, Narrative, UI. Implementation: FMOD',
   // reelLink: 'https://vimeo.com/791868864',
    gameLink: 'https://krutart.itch.io/in-the-shoes-of-a-politician',
  },
   {
        title: 'Facteroids',
        role: 'Sound Design: Engines, Buildings and UI.',
       gameLink: 'https://store.steampowered.com/app/1626530/Facteroids/',
    },
    {
        title: 'Holy Shift',
        role: 'Sound Design: Creatures and Abilities.',
        reelLink: 'https://vimeo.com/791885781',
    },
    {
        title: 'Shards of Gravity',
        role: 'Sounds Design: Creatures, Environment and Abilities.',
        gameLink: 'https://bluecrystalstudio.itch.io/shards-of-gravity',
    },
    {
        title: 'The Brave Brain',
        role: 'Sound Design: User Interface Sounds',
        gameLink: 'https://thebravebrain.com/',
    }, 
  {
      title: 'Linear Redesigns',
      role: 'Previous reels, tests and practices.',
      reelLink: 'https://vimeo.com/user153677480',
  },
]
