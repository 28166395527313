import styled from 'styled-components'
import { Col } from '@semcore/ui/grid'
import { MOBILE_BREAKPOINT } from 'helpers/constants'
import WavesUrl from 'assets/black-waves-background.svg?url'
import DiamondUrl from 'assets/diamond-illustration.svg?url'
import NoiseUrl from '../../assets/noise.png?url'
import { ContentWrapperStyles } from '../content-wrapper'

export const Container = styled.div`
  background-color: var(--bg-color);
  color: var(--font-color-secondary);
  padding-top: 9%;
  padding-bottom: 9%;
  background-image: url(${WavesUrl}), url(${NoiseUrl});
  background-size: cover, contain;
  background-repeat: no-repeat, repeat;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 14% 0;
  }

  ${ContentWrapperStyles};
`

export const SecondaryTitle = styled.h2`
  font-family: var(--accent-font);
  text-transform: uppercase;
  font-size: var(--fs-300);
  font-weight: 400;
  text-align: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: var(--fs-200);
  }
`

export const Description = styled.p`
  font-size: var(--fs-300);
  text-align: center;
  max-width: 800px;
  margin: 4% auto 0;

  @media (min-width: 2000px) {
    max-width: 75%;
  }
`

export const PrimaryTitle = styled.span`
  font-size: var(--fs-500);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1%;

  & > svg {
    width: 16vw;
    height: 6.5vw;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 27vw;
      height: 11vw;
    }
  }
`

export const ContentWrapper = styled(Col)`
  background-image: url(${DiamondUrl});
  background-repeat: no-repeat;
  background-size: 20%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 2%;
    background-size: 20%;
  }
`
