import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'

export const Link = styled.a`
  text-decoration: none;
  color: var(--font-color-secondary);
  text-transform: uppercase;
  font-weight: 700;

  &:hover {
    font-style: italic;
  }
`

export const Container = styled.li`
  border-left: 1px solid var(--fair);
  display: flex;
  padding-left: 12%;
  margin-top: 32%;
  justify-content: space-between;
  flex-direction: column;

  &:hover,
  &:hover ${Link} {
    color: var(--font-color-primary);
  }

  &:hover {
    border-color: 1px solid var(--blue);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 41%;
  }
`

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: var(--fs-300);
  font-family: var(--accent-font);
  font-weight: 400;
  margin-bottom: 11%;
  margin-top: 0;
`

export const Description = styled.p`
  font-size: var(--fs-100);
  margin-top: 0;
  margin-bottom: 16%;
`
