import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from 'helpers/constants'
import StoryPathUrl from 'assets/story-path-1.svg?url'
import StoryPathMobileUrl from 'assets/story-path-1-mobile.svg?url'

export const Container = styled.div<{ $isVisible: boolean }>`
  width: 60%;
  position: relative;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
  transition: 2s opacity ease;

  &:after {
    content: '';
    position: absolute;
    display: block;
    right: -50%;
    background-image: url(${StoryPathUrl});
    background-repeat: no-repeat;
    width: 40%;
    background-size: contain;
    height: 90%;
    bottom: -15%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;

    &:after {
      background-image: url(${StoryPathMobileUrl});
      right: -28%;
      height: 69%;
      bottom: -19%;
    }
  }
`
